/**
 * Call Counter Utility
 * 
 * This module provides functions to fetch the total number of calls powered
 * by the Dasha platform, with a fallback to fake data for development mode.
 * 
 * The API endpoint is defined in src/constants/api.js, making it easy to update
 * if the endpoint changes in the future.
 */

import { DASHA_STATS } from '../constants/api';

/**
 * Format large numbers with commas
 * @param {number} num - The number to format
 * @return {string} Formatted number string
 */
export const formatNumber = (num) => {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

/**
 * Fetch the total call count from the API
 * @return {Promise<number>} Promise resolving to the current call count
 */
export const fetchCallCount = async () => {
  
  try {
    const response = await fetch(DASHA_STATS.CALL_COUNT);
    
    if (!response.ok) {
      throw new Error(`API responded with status: ${response.status}`);
    }
    
    const data = await response.json();
    // Use 'count' from the new API response
    return data.count || 0; 
  } catch (error) {
    console.error('Error fetching call count:', error);
    // Remove fallback to fake data, throw the error instead
    throw error; 
  }
}; 