import styled, { css } from "styled-components"
import { Section as UISection } from "../uikit/Layout"
import { Hero, Paragraph } from "../uikit/Typographic"
import ExampleCode from "../uikit/ExampleCode"

// Change this value to switch between design options: 1, 2, 3, 4, or 5
const COUNTER_DESIGN = 1;

export const Section = styled(UISection)`
  padding: 0;
  margin-bottom: 144px;

  ${Paragraph} {
    margin-bottom: 32px;
  }

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-width: 280px;

    @media (max-width: 767px) {
      width: 288px;
      height: 48px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1199px) {
    ${Paragraph} {
      margin-bottom: 24px;
    }
  }

  @media (max-width: 1024px) {
    max-width: calc(1024px);
    width: 100%;
    padding: 56px 32px 0 32px;
    margin-bottom: 56px;
  }

  @media (max-width: 806px) {
    padding: 0 42px 0 42px;
  }

  @media (max-width: 768px) {
    padding: 0 40px 0 40px;
  }

  @media (max-width: 767px) {
    padding: 0 31px 0 31px;
  }

  @media (max-width: 599px) {
    padding: 0 16px 0 16px;

    ${Paragraph} {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 414px) {
    margin-bottom: 48px;
  }
`

export const Title = styled(Hero)`
  margin-top: 80px;
  margin-bottom: 16px;

  @media (max-width: 1199px) {
    margin-top: 40px;
  }

  @media (max-width: 599px) {
    margin-top: 24px;
    margin-bottom: 8px;
  }
`

export const ButtonsContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 32px;
  margin-bottom: 16px;

  /* When CounterWrapper is empty, center the button */
  & > div:empty + a {
    margin: 0 auto;
  }

  a {
    width: 294px;
    height: 56px;
  }
  
  /* Counter on left, button on right - separated by justify-content */
  & > div:first-child {
    flex: 0 0 auto;
    max-width: 50%; /* Limit the counter width to prevent overlap */
    margin-right: 24px; /* Add space between counter and buttons */
    
    /* If empty counter wrapper, make it disappear completely */
    &:empty {
      display: none;
      width: 0;
      height: 0;
      margin: 0;
      padding: 0;
    }
  }
  
  & > a {
    flex: 0 0 auto;
    margin-left: auto; /* Force to right edge */
  }

  a:first-child {
    margin-right: 32px;
  }

  a:last-child {
    color: ${props => props.theme.tryInBrowserColor};
  }

  a:last-child:hover {
    color: ${props => props.theme.tryInBrowserHoverColor};
  }

  @media (max-width: 677px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    
    /* Stack counter and button vertically with proper spacing */
    & > div:first-child {
      flex: 0 0 auto;
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
      text-align: center;
    }

    & > a {
      flex: 0 0 auto;
      margin-left: 0; /* Reset margin-left for mobile */
      margin-right: 0;
    }

    a:first-child {
      margin-right: 0;
      order: 2;
    }

    a:last-child {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 599px) {
    a {
      height: 48px;
    }
  }
`

export const CounterWrapper = styled.div`
  display: flex;
  position: relative;
  margin: 0 0 16px;
  max-width: 100%;
  width: 100%;
  
  /* DESIGN OPTION 1: Minimal & Clean - Left-aligned with subtle accent */
  ${COUNTER_DESIGN === 1 && css`
    justify-content: flex-start;
    align-items: flex-start;
    margin: 8px 0 24px;
    
    &:before {
      content: '';
      position: absolute;
      top: -8px;
      left: 0;
      width: 40px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors?.cyan[500]};
      opacity: 0.6;
    }
  `}
  
  /* DESIGN OPTION 2: Modern Box - Centered with border & background */
  ${COUNTER_DESIGN === 2 && css`
    justify-content: center;
    align-items: center;
    padding: 16px 20px;
    margin: 16px 0 24px;
    border: 2px solid ${({ theme }) => theme.colors?.cyan[500]};
    border-radius: 8px;
    background-color: rgba(2, 204, 203, 0.05);
  `}
  
  /* DESIGN OPTION 3: Elegant Divider - Full-width with separator lines */
  ${COUNTER_DESIGN === 3 && css`
    justify-content: center;
    align-items: center;
    margin: 32px 0;
    padding: 24px 0;
    position: relative;
    
    &:before, &:after {
      content: '';
      position: absolute;
      height: 2px;
      width: 100%;
      background: linear-gradient(90deg, rgba(2, 204, 203, 0.1), rgba(2, 204, 203, 0.9), rgba(2, 204, 203, 0.1));
      opacity: 1;
    }
    
    &:before {
      top: 0;
    }
    
    &:after {
      bottom: 0;
    }
  `}
  
  /* DESIGN OPTION 4: Offset Highlight - Right-aligned with vertical accent */
  ${COUNTER_DESIGN === 4 && css`
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
    padding-right: 28px;
    margin: 12px 0 24px;
    
    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 8px;
      height: 100%;
      background-color: ${({ theme }) => theme.colors?.cyan[500]};
      opacity: 1;
      border-radius: 4px 0 0 4px;
    }
  `}
  
  /* DESIGN OPTION 5: Premium Card - Floating with shadow & glow effect */
  ${COUNTER_DESIGN === 5 && css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 28px 0 36px;
    padding: 24px;
    background: linear-gradient(145deg, rgba(15, 23, 42, 0.8), rgba(23, 31, 56, 0.9));
    border-radius: 12px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4), 0 0 15px rgba(2, 204, 203, 0.15);
    backdrop-filter: blur(4px);
    border: 1px solid rgba(2, 204, 203, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transform: translateY(0);
    transition: all 0.3s ease;
    
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: linear-gradient(90deg, transparent, rgba(2, 204, 203, 0.3), transparent);
    }
    
    &:hover {
      box-shadow: 0 10px 40px rgba(0, 0, 0, 0.4), 0 0 20px rgba(2, 204, 203, 0.25);
      transform: translateY(-3px);
    }
  `}
  
  @media (max-width: 599px) {
    margin: ${COUNTER_DESIGN === 2 ? '12px 0 18px' : 
            COUNTER_DESIGN === 5 ? '20px 0 28px' : '8px 0 16px'};
    padding: ${COUNTER_DESIGN === 2 ? '12px 16px' : 
             COUNTER_DESIGN === 5 ? '18px' : '0'};
  }
`;

export const Container = styled.div`
  margin: 50px auto;
  position: relative;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    z-index: -1;
    position: absolute;
    display: block;
    margin: auto;
    border-radius: 16px;
    background: rgb(113, 111, 246);
    left: -20px;
    right: -20px;
    top: 46px;
    bottom: 47px;

    @media (max-width: 600px) {
      display: none;
    }
  }
`

export const CodeBlock = styled(ExampleCode)`
  min-width: 585px;
  width: 585px;
  height: 494px;
  border-radius: 16px;
  overflow: hidden;
`

export const Terminal = styled.div`
  width: 534px;
  height: 456px;
  background: #21254b;
  border-radius: 0 16px 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > button {
    width: 240px;
    min-height: 40px;
    height: 40px;
    position: absolute;
    bottom: 24px;
    margin-bottom: 26px;

    svg {
      margin-left: 8px;
    }
  }
`

export const Video = styled.div`
  position: relative;
  padding-top: 56.25%;
  margin-top: 40px;
  width: 100%;

  @media (max-width: 1199px) {
    margin-top: 36px;

    ::before {
      display: none;
    }
  }

  @media (max-width: 1024) {
    margin-top: 36px;
  }

  @media (max-width: 599px) {
    margin-top: 28px;
  }

  iframe {
    background: #edf0ff;
    position: absolute;
    border-radius: 32px;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;

    @media (max-width: 1199px) {
      border-radius: 24px;
    }

    @media (max-width: 599px) {
      border-radius: 16px;
    }
  }

  svg {
    width: 96px;
    height: 96px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -48px;
    z-index: 1;
    transition: 0.5s all;

    &:hover {
      transform: scale(1.1);
    }

    &.hide {
      pointer-events: none;
      transform: scale(0.9);
      opacity: 0;
    }

    @media (max-width: 600px) {
      width: 60px;
      height: 60px;
      margin: -30px;
    }
  }

  ::before {
    content: "";
    position: absolute;
    background: #716ff6;
    border-radius: 16px;
    top: 40px;
    bottom: 40px;
    left: -20px;
    right: -20px;
    z-index: -1;

    @media (max-width: 600px) {
      display: none;
    }
  }
`
export const AdditionalText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  button {
    min-width: 145px;
    width: fit-content;
    height: 40px;
    padding: 0 8px;
  }

  .text {
    display: inline-block;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: ${props => props.theme.forSalesText};
    margin-right: 24px;
  }

  button:not(:disabled):active,
  button:not(:disabled):hover,
  button {
    ${props =>
      props.theme.buttonInverseColor &&
      "color: " + props.theme.buttonInverseColor}
  }

  @media (max-width: 599px) {
    margin: 0 auto;
    flex-direction: column;
    align-items: center;

    button {
      width: 288px;
    }

    .text {
      margin-right: 0;
      margin-bottom: 16px;
    }

    button {
      width: 288px;
      height: 48px;
    }
  }
`

export const CenteredButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 16px;

  a {
    width: 294px;
    height: 56px;
  }

  @media (max-width: 599px) {
    a {
      height: 48px;
    }
  }
`