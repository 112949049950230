import React, { useRef, useEffect } from "react"

import { Paragraph, Caption } from "../uikit/Typographic"
import Button from "../../newUikit/Button"
import CallCounter from "../CallCounter"
import * as S from "./styled"

// Change this value to switch between placement options: 1, 2, 3, 4
const COUNTER_PLACEMENT = 4;

const MainSection = ({ content, openModal }) => {
  const ref = useRef()

  useEffect(() => {
    if (!ref.current) return
  }, [ref])

  // ALTERNATIVE PLACEMENT OPTIONS:
  // Option A: Move above the title for maximum prominence
  // Option B: Move after the paragraph for a logical flow
  // Option C: Place next to the CTA button for action emphasis

  // Only create counter component if label exists
  // This is the important fix - don't create an empty counter
  const counterComponent = content.callCounterLabel ? (
    <S.CounterWrapper>
      <CallCounter 
        label={content.callCounterLabel} 
        subheading={content.callCounterSubheading || ""}
      />
    </S.CounterWrapper>
  ) : null;

  return (
    <S.Section id="MainSection">
      {COUNTER_PLACEMENT === 2 && counterComponent}
      
      <S.Title>{content.title}</S.Title>
      
      {COUNTER_PLACEMENT === 1 && counterComponent}
      
      <Paragraph>{content.description}</Paragraph>
      
      {COUNTER_PLACEMENT === 3 && content.callCounterLabel && counterComponent}
      
      {/* Render the "Try in Playground" button */}
      {(() => {
        // Create the button element once to avoid duplication
        const buttonElement = content.tryInBrowserLabel && (
          <Button
            color="cyan"
            target="_blank"
            url={content.tryInBrowser}
          >
            {content.tryInBrowserLabel}
          </Button>
        );
        
        // Determine if we should show counter at position 4
        const showCounterAtPosition4 = content.callCounterLabel && COUNTER_PLACEMENT === 4;
        
        // Use the appropriate container based on whether counter exists
        return showCounterAtPosition4 ? (
          <S.ButtonsContainer>
            {counterComponent}
            {buttonElement}
          </S.ButtonsContainer>
        ) : (
          <S.CenteredButtonContainer>
            {buttonElement}
          </S.CenteredButtonContainer>
        );
      })()}
      
      <S.Container>
      <S.AdditionalText>
            <span className="text">{content.forSales}</span>
            <Button size="sm" inverse onClick={openModal}>
              {content.salesBtn}
            </Button>
      </S.AdditionalText>
      </S.Container>
      { content.video && <Caption>{content.watchDemo}</Caption> }
      { content.video && <S.Video ref={ref}>
          <iframe 
            src="https://demo.arcade.software/lWMndRj4Bau3tCqzXdoC?embed&show_copy_link=true" 
            title="Dasha Studio" 
            frameBorder="0" 
            loading="lazy" 
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
            allow="clipboard-write"
            style={{
              position: "absolute", 
              top: 0, 
              left: 0, 
              width: "100%", 
              height: "100%", 
              colorScheme: "light"
            }}
          />
      </S.Video> }

      {/*<S.Container>
        <S.CodeBlock samples={content.code} />
        <S.Terminal>
          {!isTerminal ? (
            <>
              <TerminalIcon />
              <Button onClick={() => setTerminal(true)}>
                Run <RunIcon />
              </Button>
            </>
          ) : (
            <Terminal />
          )}
        </S.Terminal>
          </S.Container>*/}
    </S.Section>
  )
}

export default MainSection
