/**
 * API Endpoints Constants
 * 
 * This file contains all API endpoints used throughout the application.
 * Centralizing them here makes it easier to update if endpoints change in the future.
 */

// Dasha Statistics Endpoints
export const DASHA_STATS = {
  CALL_COUNT: 'https://app.us.dasha.ai/api/v1/misc/stats/aggregated/count'
};