import React, { useState, useEffect, useCallback, useRef } from 'react';
import { fetchCallCount, formatNumber } from '../../utils/callCounter';
import * as S from './styled';

/**
 * CallCounter component that displays the total number of calls
 * powered by Dasha platform, with real-time updates and a subheading.
 */
const CallCounter = ({ 
  label,
  subheading
}) => {
  const [count, setCount] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const prevCountRef = useRef(null);
  const [hasReceivedFirstValue, setHasReceivedFirstValue] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const lastUpdateTimeRef = useRef(0);
  const MIN_UPDATE_INTERVAL = 1000; // Minimum 2 seconds between visual updates

  // Function to fetch the call count and update the state
  const updateCount = useCallback(async () => {
    // Prevent concurrent API calls
    if (isFetching) return;
    
    // Check if minimum time has passed since last UI update
    const now = Date.now();
    if (now - lastUpdateTimeRef.current < MIN_UPDATE_INTERVAL && hasReceivedFirstValue) {
      return;
    }
    
    try {
      setIsFetching(true);
      const newCount = await fetchCallCount();
      
      // For initial load or if new count is greater than current count
      if (count === null || newCount > count) {
        // Only trigger the animation if count has changed
        if (prevCountRef.current !== newCount) {
          setIsUpdating(true);
          setTimeout(() => setIsUpdating(false), 600);
          prevCountRef.current = newCount;
          lastUpdateTimeRef.current = now; // Record the time of this update
        }
        
        setCount(newCount);
        
        // Mark that we've received the first value
        if (!hasReceivedFirstValue) {
          setHasReceivedFirstValue(true);
        }
      }
    } catch (err) {
      console.error('Failed to fetch call count:', err);
      
      // Only mark as received first value if we already have a count
      if (count === null) {
        // Do not show the counter at all if initial fetch fails
      }
    } finally {
      setIsFetching(false);
    }
  }, [count, hasReceivedFirstValue, isFetching]);

  // Initial fetch on component mount with a slight delay
  useEffect(() => {
    const initialFetchTimer = setTimeout(() => {
      updateCount();
    }, 100); // Small delay before initial fetch to avoid React strict mode issues
    
    return () => clearTimeout(initialFetchTimer);
  }, [updateCount]);

  // Set up periodic updates
  useEffect(() => {
    const intervalId = setInterval(() => {
      updateCount();
    }, MIN_UPDATE_INTERVAL);

    // Clean up interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, [updateCount]);

  // Don't render anything until we've received the first value
  if (!hasReceivedFirstValue) {
    return null;
  }

  // Simple formatting without any special treatment
  const formattedCount = count !== null ? formatNumber(count) : '0';

  return (
    <S.Container>
      <S.Label>{label}</S.Label>
      <S.Counter isUpdating={isUpdating}>
        {formattedCount}
      </S.Counter>
      {subheading && <S.Subheading>{subheading}</S.Subheading>}
    </S.Container>
  );
};

export default CallCounter; 