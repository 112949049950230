import styled, { keyframes, css } from 'styled-components';
import theme from '../../uikit/theme';

// Get media from theme
const { media, colors } = theme;

// Added for context - match this with MainSection/styled.js
// The CallCounter won't access this directly - it's just for reference
const COUNTER_DESIGN = 1;

// Subtle fade animation
const fadeIn = keyframes`
  from {
    opacity: 0.7;
  }
  to {
    opacity: 1;
  }
`;

// Remove pulse animation since we don't use it anymore

// Container for the call counter - adapts to different designs
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  text-align: center;
  
  ${COUNTER_DESIGN === 1 && css`
    align-items: flex-start;
    text-align: left;
  `}
  
  ${COUNTER_DESIGN === 4 && css`
    align-items: flex-end;
    text-align: right;
    width: 100%;
  `}
  
  ${COUNTER_DESIGN === 5 && css`
    align-items: center;
    text-align: center;
    width: 100%;
  `}
`;

// Professional label design
export const Label = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: ${props => props.theme.text};
  letter-spacing: 0.5px;
  margin-bottom: 2px;
  opacity: 1;
  
  ${COUNTER_DESIGN === 4 && css`
    text-align: right;
    width: 100%;
  `}
  
  ${COUNTER_DESIGN === 5 && css`
    color: ${props => props.theme.text};
    opacity: 1;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 4px;
  `}
  
  @media ${media.tablet} {
    font-size: 15px;
  }
`;

// Clean, professional counter display
export const Counter = styled.div`
  font-size: 58px;
  font-weight: 700;
  color: ${colors.cyan[500]};
  line-height: 1;
  letter-spacing: -0.5px;
  /* Use a specific fixed width for the text container */
  width: 240px;
  display: block;
  text-align: right;
  /* Critical: Fixed character width for digits */
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  
  animation: ${props => props.isUpdating ? fadeIn : 'none'} 0.5s ease-out;
  
  ${COUNTER_DESIGN === 3 && css`
    font-size: 64px;
    margin: 4px 0;
  `}
  
  ${COUNTER_DESIGN === 4 && css`
    text-align: right;
  `}
  
  ${COUNTER_DESIGN === 5 && css`
    font-size: 62px;
    font-weight: 800;
    margin: 4px 0;
  `}
  
  @media ${media.tablet} {
    font-size: 64px;
    
    ${COUNTER_DESIGN === 3 && css`
      font-size: 72px;
    `}
    
    ${COUNTER_DESIGN === 5 && css`
      font-size: 72px;
    `}
  }
  
  @media ${media.desktop} {
    font-size: 74px;
    
    ${COUNTER_DESIGN === 5 && css`
      font-size: 82px;
    `}
  }
  
  @media (max-width: 599px) {
    font-size: 46px;
    
    ${COUNTER_DESIGN === 5 && css`
      font-size: 52px;
    `}
  }
`;

// Subheading below the counter
export const Subheading = styled.div`
  font-size: 16px;
  font-weight: 700;
  /* Use theme.text which is #001553 in light theme and #fff in dark theme */
  color: ${props => props.theme.text};
  margin-top: 8px;
  letter-spacing: 0.3px;
  line-height: 1.4;
  opacity: 1;
  
  ${COUNTER_DESIGN === 4 && css`
    text-align: right;
    width: 100%;
  `}
  
  ${COUNTER_DESIGN === 5 && css`
    text-align: center;
    letter-spacing: 0.5px;
  `}
  
  @media ${media.tablet} {
    font-size: 18px;
  }
  
  @media (max-width: 599px) {
    font-size: 14px;
    margin-top: 6px;
  }
`;

// Minimal loading state
export const Loading = styled.div`
  color: ${colors.grey[600]};
  font-size: 16px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${COUNTER_DESIGN === 5 && css`
    color: ${colors.cyan[300]};
  `}
`;

// Clean error state
export const Error = styled.div`
  color: ${colors.red[100]};
  margin-top: 6px;
  font-size: 14px;
  text-align: center;
`; 